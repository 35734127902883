import lity from "lity";
import smoothscroll from "smoothscroll-polyfill";
import collapseNav from "./collapseNav";
import drawerNav from "./drawerNav";
import formConfirm from "./formConfirm";
import LazyDisp from "./lazydisp";
import ScrollDir from "./scrollDir";
import subNav from "./subNav";
// import FirstViewEndObserver from "../extended_class/FirstViewEndObserver";

const [w,d,l,mq] = [window, document, location, window.matchMedia( "(max-width: 900px)" ) ];

export default class RWD002 {
  constructor(opt) {
    // settings
    this.opt = Object.assign({
      loading: true,
      loadingClass: 'is-loading',
      gnav: '[data-role="globalNav"]',
      fnav: '[data-role="footerNav"]',
      drawerNav: '[data-plugin="drawerNav"]',
      collapse: '[data-plugin="collapse"]',
      lazydisp: '[data-lazydisp]',
      csspreload: 'link[rel="preload"][as="style"]',
      formConfirm: '[data-plugin="formConfirm"]',
      fixNav : false,
      viewport: {
        mq: w.matchMedia('screen and (min-width:1195px)'),
        content_device: 'width=device-width,initial-scale=1',
        content_scale: 'width=1280'
      },
      hashscroll: {
        pagetopHash: '#pagetop',
        mq: w.matchMedia('(max-width:899px)')
      },
      zip2addr: '[data-plugin="zip2addr"]'
    }, opt);

    // Promiseオブジェクトを生成、実行
    this.promises = {
      init: new Promise((resolve) => this.initResolver = resolve),
      domReady: new Promise((resolve) => {
        d.addEventListener('DOMContentLoaded', () => {
          this.domReady();
          resolve(this);
        }, {once: true});
      }),
      windowLoad: new Promise((resolve) => {
        w.addEventListener('load', () => {
          this.windowLoad();
          resolve(this);
        }, {once: true});
      }),
    };
    
    // サブナビ登録用クラスのインスタンス化
    this.Subnav = new subNav(this.opt);

    // スクロール方向を判定するインスタンス
    this.ScrollDir = new ScrollDir();

    // drawerNavインスタンス
    this.drawerNav = new drawerNav(this.opt.drawerNav);

    // コメントフォーム確認インスタンス
    this.formConfirm = new formConfirm(this.opt.formConfirm);

  }

  init() {
    // イベント発火
    d.dispatchEvent(new CustomEvent('rwd002.beforeInit', {detail: this}));

    // ページローディングエフェクト
    if( this.opt.loading ) this.loading();

    // initの解決
    this.initResolver(this);

    // Viewportの書き換え
    this.viewport();
  }
  
  domReady() {
    // イベント発火
    d.dispatchEvent(new CustomEvent('rwd002.beforeDomready', {detail: this}));

    // drawerNav開始
    this.drawerNav.init();

    // LazyDisp開始
    this.LazyDisp = new LazyDisp(this.opt.lazydisp);

    // collapseNav開始
    this.collapseNav = new collapseNav(this.opt.collapse);

    // FixNav
    if( this.opt.fixNav ) this.fixNav( this.opt.fixNav );

    // コメントフォーム確認開始
    this.formConfirm.init();

    // pagetopなどhashスクロール
    this.hashscroll();

    // lightbox
    $(d).on('click', 'a[rel="lightbox"]', lity);

    // 郵便番号から住所を取得
    this.zip2addr();
  }

  windowLoad() {
    // イベント発火
    d.dispatchEvent(new CustomEvent('rwd002.beforeWindowload', {detail: this}));

    // スタイル遅延ロード開始
    this.csspreload();
  }

  promiseAll() {
    return Promise.all( Object.values(this.promises) );
  }

  loading() {
    d.documentElement.style.opacity = "0";
    d.documentElement.classList.add( this.opt.loadingClass );
    d.documentElement.classList.add( 'is-pageload-available' );
    w.addEventListener('load', () => {
      d.documentElement.classList.remove( this.opt.loadingClass );
      d.documentElement.style.opacity = '';
    });
  }

  fixNav (selector) {
    // fixnavがある場合にクラスをトグルする
    const elm = d.querySelector(selector);
    d.addEventListener('Scroll.scrollUp', () => elm.classList.add('is-active') );
    d.addEventListener('Scroll.scrollDown', () => elm.classList.remove('is-active') );
  }

  hashscroll(top = 0) {
    smoothscroll.polyfill();
    let { pagetopHash, mq } = this.opt.hashscroll;
    // pagetopハッシュの処理
    const scroll = () => {
      if( !l.hash ) return;
      if( l.hash === pagetopHash ) {
        w.history.replaceState(null, '', l.pathname + l.search);
      }else{
        const targetElement = d.querySelector(l.hash);
        const headerElement = d.querySelector('header.rwd002-header');
        // 画面上部から要素までの距離
        const rectTop = (targetElement)? targetElement.getBoundingClientRect().top : 0;
        // スクロール位置に持たせるバッファ
        const buffer = (mq.matches)? headerElement.offsetHeight : 0;
        top = rectTop + w.pageYOffset - buffer;
      }
      w.scrollTo({ top, behavior: 'smooth' });
    }
    scroll();
    w.addEventListener('hashchange', scroll );
  }

  viewport () {
    // viewportの内容を変更
    const { mq, content_device, content_scale } = this.opt.viewport;
    const meta = d.querySelector('meta[name="viewport"]');
    const mqListener = e => {
      if (e.matches) {
        meta.setAttribute('content', content_scale);
      } else {
        meta.setAttribute('content', content_device);
      }
    };
    mq.addEventListener("change", mqListener);

    // 初期化処理
    mqListener(mq);
  }

  zip2addr () {
    const btns = d.querySelectorAll(this.opt.zip2addr);
    const Zenkaku2Hankaku = str => str.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
    const applyAddr = (res, pref, addr) => {
      pref.value = res.address1;
      addr.value = res.address2 + res.address3;
    }
    const getAddr = btn => {
      const apiurl = new URL('https://zipcloud.ibsnet.co.jp/api/search');
      const container = btn.closest('.rwd002-formitem');
      let zip = container.querySelector('[name="GetAdrsZip"]').value;
      const pref = container.querySelector('[name="GetAdrsPref"]');
      const addr = container.querySelector('[name="GetAdrs"]');
      zip = Zenkaku2Hankaku( zip ).replace(/(-|−|ー)/g, '');
      apiurl.searchParams.append('zipcode', Zenkaku2Hankaku(zip));
      fetch(apiurl.href).then( res => res.json() ).then(res => {
        // 住所が正しく取得できた場合
        if( res.results && res.results.length > 0 ) applyAddr(res.results[0], pref, addr);
        // イベント発火
        d.dispatchEvent(new CustomEvent('rwd002.zip2addr', {detail: {btn, res}}));
      });
    }
    btns.forEach( btn => btn.addEventListener('click', () => getAddr(btn) ) );
  }

  csspreload() {
    const links = d.querySelectorAll( this.opt.csspreload );
    links.forEach( link => link.rel = "stylesheet" );
  }

}