import TAGSORT,{taglist} from "../class/tagsort";

const [d] = [document];

const relatedItemTemplate =  `
  <div class="service-list connection-list is-related" v-if="relatedArticles.length">
    <h3 class="service-list__title connection-list__title">関連業務</h3>
    <div class="service-list__inner connection-list__inner">
      <div v-for="(article , ind) in relatedArticles">
        <a :href="article.href">
          <figure>
            <img :src="article.thumb" :alt="article.title">
          </figure>
          <p>{{ article.title }}</p>
        </a>
      </div>
    </div>
  </div>
  `;

class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    const container = d.querySelector( this.opt.container || '.rwd002-container');
    const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    const is_detail = d.body.classList.contains('is--detail');
    if( !container ) return false;

    if( is_detail ){
      $('.rwd002-detail__image').insertAfter('.rwd002-detail__title');
      $('.is-head').remove();
      pagination.insertAdjacentHTML('afterend', tag);
    }
  }
}

const domReady = () => {
  const is_detail = d.body.classList.contains('is--detail');
  if(is_detail){
    const Tagsort = new TAGSORT({
      related: {
        container: '.rwd002-pagination',
        itemTemplate: relatedItemTemplate
      }
    });
  
    d.addEventListener('tagsort.beforeInit', e => {
      const TAGSORT = e.detail;
      Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
    });
  
    Tagsort.render();
  }
};

export default function SERVICE () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}