import TAGSORT,{taglist} from "../class/tagsort";

const [w,d] = [window,document];

const itemTemplate =  `
  <div class="news-list" :class="[ 'is-paged-' + paged ]">
    <div class="news-list__inner">
      <div v-for="(article , ind) in pagedArticles">
        <a :href="article.href" class="news-item">
          <figure>
            <img :src="article.thumb" :alt="article.title">
          </figure>
          <div class="text_wrap">
            <p class="info">
              <span class="date">{{ datetostr(article.date * 1000, 'YYYY.MM.DD') }}</span>
              <span class="cate" v-if="article.category.name">{{ article.category.name }}</span>
            </p>
            <p class="ttl"><span>{{ article.title }}</span></p>
          </div>
        </a>
      </div>
    </div>
  </div>
  `;

const relatedItemTemplate =  `
  <div class="news-list is-related" v-if="relatedArticles.length">
    <h3 class="news-list__title">関連実績</h3>
    <div class="news-list__inner">
      <div v-for="(article , ind) in relatedArticles">
        <a :href="article.href" class="news-item">
          <span class="cat" v-if="article.category.name">{{ article.category.name }}</span>
          <div class="news-item__img-wrap">
            <img :src="article.thumb" :alt="article.title" class="news-item__img" width="355" height="355">
          </div>
          <div class="news-item__body">
            <span class="title">{{ article.title }}</span>
            <span class="tags" v-if="article.keywords.length">
              <span class="tag" v-for="(tag, ind) in article.keywords">#{{ tag }}</span>
            </span>
          </div>
          <!-- /.news-item__body -->
        </a>
        <!-- /.news-item-->
      </div>
    </div>
  </div>
  `;


class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    const container = d.querySelector( this.opt.container || '.rwd002-container');
    const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    const is_list = d.body.classList.contains('is--list');
    if( !container ) return false;

    // top
    if( is_list ){
      container.insertAdjacentHTML('beforebegin', tag);
    }

    // bottom
    if( pagination ){
      pagination.insertAdjacentHTML('afterend', tag);
    }else {
      container.insertAdjacentHTML('afterend', tag);
    }

  }
}

const domReady = () => {
  
  const Tagsort = new TAGSORT({
    list: {
      itemTemplate,
      perpage: 4, // 一度に表示する記事数
    },
    taglist: {
      pagination: '.rwd002-pagination', // taglistを表示する基準の要素
    },
    related: {
      disp_detail: false,
    }
  });

  d.addEventListener('tagsort.beforeInit', e => {
    const TAGSORT = e.detail;
    Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
  });

  Tagsort.render();
};

export default function NEWS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}